<template>
  <div class="contact-container">
    <div class="flex-col container-title-box">
      <div class="box-title">
        <h3>联系我们</h3>
      </div>
      <div class="flex-row box-list">
        <div class="flex-row box-item" v-for="bItem in contactList" :key="bItem.id">
          <div class="flex-col item-left">
            <p>{{bItem.title}}</p>
            <span v-if="bItem.id === 1"><a :href="'tel:' + bItem.text">{{bItem.text}}</a></span>
            <span v-else>{{bItem.text}}</span>
          </div>
          <div class="item-image">
            <img :src="require(`@/assets/icons/${bItem.image}.png`)" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="amap-wrapper">
        <el-amap class="amap-box" :vid="'amap-vue'" :zoom='16' :center="centerPosition">
          <el-amap-marker :icon="icon" title="衣橱"></el-amap-marker>
        </el-amap>
      </div>
      <div class="flex-row map-address">
        <div class="flex-content address-icon">
          <img src="@/assets/icons/position.png" alt="">
        </div>
        <h4>公司地址</h4>
        <p>浙江省杭州市上城区新塘路1366号铁牛大厦</p>
      </div>
    </div>
  </div>
</template>

<script>
import locationIcon from '@/assets/icons/position.png'
// import AMap from 'vue-amap'
export default {
  name: 'Contact',
  data () {
    return {
      centerPosition: [120.262413, 30.305211],
      icon: locationIcon,
      contactList: [
        {
          id: 1,
          title: '客服电话',
          text: '400-0571-123',
          image: 'contact-item1'
        },
        {
          id: 2,
          title: '客户意见',
          text: 'support@jingzhiyichu.com',
          image: 'contact-item2'
        },
        {
          id: 3,
          title: '供应商合作',
          text: 'business@jingzhiyichu.com',
          image: 'contact-item3'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang='less' scoped>
@media screen and(max-width:900px) {
  .contact-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f6f6;
    padding: 10px;
    box-sizing: border-box;
    .container-title-box {
      width: 100%;
      .box-title {
        font-size: 20px;
        display: none;
      }
      .box-list {
        flex-direction: column;
        .box-item:first-child {
          margin-top: 0;
        }
        .box-item {
          width: 100%;
          justify-content: space-between;
          background: #FFFFFF;
          margin-top: 10px;
          border-radius: 10px;
          .item-left {
            padding: 10px 0 0 10px;
            p {
              font-size: 20px;
              font-weight: bold;
              color: rgb(201, 0, 16);
            }
            span {
              font-size: 16px;
              margin-top: 10px;
            }
            a {
              color: #000;
            }
          }
          .item-image {
            width: 110px;
            height: 110px;
          }
        }
      }
    }
    .map {
      width: 100%;
      height: 3.5rem;
      margin-top: 15px;
      padding-bottom: .8rem;
      .amap-wrapper {
        width: 100%;
        height: 100%;
        /deep/ .amap-icon {
          width: 30px;
          height: 30px;
        }
        /deep/ .amap-container {
          border-radius: 10px;
        }
      }
      .map-address {
        align-items: center;
        margin-top: 10px;
        .address-icon {
          width: 30px;
          height: 30px;
        }
        h4 {
          font-size: 18px;
          color: rgb(201, 0, 16);
          font-weight: bold;
          margin-left: 10px;
        }
        p {
          font-size: 13px;
          margin-left: 10px;
        }
      }
    }
  }
}
@media screen and(min-width:900px){
  .contact-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .container-title-box {
      width: 100%;
      background-image: url(../../../assets/images/contact-bg.png);
      background-size: cover;
      height: 480px;
      align-items: center;
      .box-title {
        width: 80%;
        max-width: 1200px;
        margin-top: 60px;
        h3 {
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
      .box-list {
        width: 80%;
        max-width: 1200px;
        justify-content: space-between;
        .box-item {
          margin-top: 45px;
          width: 31%;
          // height: 150px;
          padding-top: 60px;
          height: 200px;
          background: #FFFFFF;
          border-radius: 20px;
          box-sizing: border-box;
          .item-left {
            box-sizing: border-box;
            width: 60%;
            height: 100%;
            // border-radius: 0 0 0 20px;
            padding: 0 0 0 50px;
            background: #fbfbfb;
            p {
              font-size: 24px;
              font-weight: 700;
              color: rgb(201, 0, 16);
              margin-top: 10px;
            }
            span {
              font-size: 18px;
              margin-top: 8px;
            }
            a {
              color: #000;
            }
          }
          .item-image {
            width: 40%;
          }
        }
      }
    }
    .map {
      margin-top: -100px;
      width: 80%;
      max-width: 1200px;
      height: 380px;
      padding-bottom: 150px;
      .amap-wrapper {
        width: 100%;
        height: 100%;
        /deep/ .amap-icon {
          width: 30px;
          height: 30px;
        }
      }
      .map-address {
        align-items: center;
        margin-top: 50px;
        .address-icon {
          width: 30px;
          height: 30px;
        }
        h4 {
          font-size: 18px;
          color: rgb(201, 0, 16);
          font-weight: bold;
          margin-left: 10px;
        }
        p {
          font-size: 13px;
          margin-left: 10px;
        }
      }
    }
  }
}

</style>
