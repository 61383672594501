<template>
  <div class="introduction-container">
    <div class="red-bar"></div>
    <!-- 公司简介 -->
    <div class="flex-row company-profile">
      <div class="profile-image">
        <img src="@/assets/images/gongsidalou.png" alt="">
      </div>
      <div class="flex-col profile-right">
        <div class="profile-title">
          <p>项目介绍</p>
        </div>
        <div class="flex-col profile-text">
          <p>{{profileText1}}</p>
          <p>{{profileText2}}</p>
        </div>
      </div>
    </div>
    <!-- 平台优势 -->
    <div class="platform-advantage">
      <div class="advantage-title">
        <p>平台优势</p>
      </div>
      <div class="advantage-tabs">
        <div class="tabs-bg"></div>
        <div class="flex-row tabs-list">
          <div class="flex-col" :class="aItem.active ? 'tabs-item-active' : 'tabs-item'" @mouseenter.stop="mouseenter(aItem)"  v-for="aItem in advantageTabsList" :key="aItem.id">
            <img v-if="!aItem.active" :src="require(`@/assets/icons/${aItem.image}.png`)" alt="">
            <img v-if="aItem.active" :src="require(`@/assets/icons/${aItem.image1}.png`)" alt="">
            <h1>{{aItem.title}}</h1>
            <p>{{aItem.text1}}</p>
            <p>{{aItem.text2}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 全方位赋能 -->
    <div class="all-assigned">
      <div class="assigned-title">
        <p>入驻店主优势</p>
      </div>
      <div class="flex-row assigned-list">
        <div class="flex-content assigned-item" v-for="aItem in assignedList" :key="aItem.id">
          <div class="flex-col item-border">
            <div class="flex-content item-image">
              <img :src="require(`@/assets/icons/assigned/${aItem.image}.png`)">
            </div>
            <div class="flex-col item-text">
              <div class="text-title">{{aItem.title}}</div>
              <div class="row-box">
                <div class="text-row" v-for="(tItem, tIndex) in aItem.text" :key="tIndex">
                  <p>{{tItem}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduction',
  data () {
    return {
      profileText1: '衣橱掌柜是一个专为代购、团购、微商、实体店等“私域渠道”提供高品质货源的批发系统，汇集了国内数百家外贸大牌代工厂货源，提供全量免检高标准货源，甄选女装男装、母婴童装、潮流运动、家具床品、家居百货、美妆个护、配饰箱包等等全品类生活爆品，追溯到面料源头更具优势，致力于打造高品质源头供应链批发平台而不懈努力。',
      profileText2: '衣橱掌柜全方位赋能B端客户，提供极致性价比产品、一件代发、不囤货、高复购、坚持诚信、利他、共同富裕，真正做到精选好货、匠心品质、乐怡生活。',
      advantageTabsList: [
        {
          id: 1,
          image: 'lingyuankaidian',
          image1: 'lingyuankaidian-sp',
          title: '无负担',
          text1: '0元开店',
          text2: '无资金负担',
          active: true
        },
        {
          id: 2,
          image: 'shenhe',
          image1: 'shenhe-sp',
          title: '保质量',
          text1: '严格审核',
          text2: '源头供应链',
          active: false
        },
        {
          id: 3,
          image: 'heguihefa',
          image1: 'heguihefa-sp',
          title: '爆品稳',
          text1: '爆品经过',
          text2: '多维度测试',
          active: false
        },
        {
          id: 4,
          image: 'mianjianchanping',
          image1: 'mianjianchanping-sp',
          title: '全量检品',
          text1: '出口日本级检验标准',
          text2: '保证商品品质',
          active: false
        },
        {
          id: 5,
          image: 'baopin',
          image1: 'baopin-sp',
          title: '合法利润',
          text1: '合规合法',
          text2: '无法律风险',
          active: false
        },
        {
          id: 6,
          image: 'jiandantixian',
          image1: 'jiandantixian-sp',
          title: '简单提现',
          text1: '周周提现',
          text2: '简单好操作',
          active: false
        }
      ],
      assignedList: [
        {
          id: 1,
          image: 'siyu',
          title: '私域',
          text: ['只做批发和服务', '不碰你的客户']
        },
        {
          id: 2,
          image: 'gaoshouyi',
          title: '高收益',
          text: ['源头面料直供', '一线代工厂大批量生产']
        },
        {
          id: 3,
          image: 'niuping',
          title: '牛品',
          text: ['苛刻选品 独家封闭货源', '一线代工厂']
        },
        {
          id: 4,
          image: 'fangxin',
          title: '放心',
          text: ['七天无理由退换货', '假一赔十 人工客服']
        },
        {
          id: 5,
          image: 'funeng',
          title: '赋能',
          text: ['无需囤货 一件代发', '专业素材 全程辅导']
        },
        {
          id: 6,
          image: 'hefa',
          title: '合法',
          text: ['合法合规', '安心经营']
        }
      ]
    }
  },
  methods: {
    mouseenter (val) {
      if (!val.active) {
        this.advantageTabsList.map(v => {
          v.active = false
        })
        val.active = !val.active
      }
    }
  }
}
</script>

<style lang='less' scoped>
@media screen and(max-width: 900px){
  .introduction-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    background: #f4f4f4;
    .company-profile {
      width: 100%;
      border-radius: 10px;
      background: #FFFFFF;
      .profile-image {
        display: none;
      }
      .profile-right {
        width: 100%;
        padding: 28px 12px 12px 12px;
        .profile-title {
          width: 4.2rem;
          height: .22rem;
          font-size: 20px;
          font-weight: bold;
          background-image: url(../../../assets/images/company-intr-mobile.png);
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          p {
            position: absolute;
            top: -.34rem;
            padding-bottom: .3rem;
            border-bottom: 2px solid #000;
          }
        }
        .profile-text {
          font-size: 15px;
          margin-top: 18px;
          text-align: justify;
          font-weight: 300;
          p {
            letter-spacing: 3px;
          }
          p:last-child {
            margin-top: 15px;
          }
        }
      }
    }
    .platform-advantage {
      width: 100%;
      border-radius: 10px;
      background: #FFFFFF;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 28px 12px 12px 12px;
      box-sizing: border-box;
      .advantage-title {
        width: 3.95rem;
        height: .22rem;
        font-size: 20px;
        font-weight: bold;
        background-image: url(../../../assets/images/platformadv-mobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        p {
          position: absolute;
          top: -.34rem;
          padding-bottom: .3rem;
          border-bottom: 2px solid #000;
        }
      }
      .advantage-tabs {
        margin-top: 15px;
        .tabs-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          .tabs-item {
            width: 47%;
            align-items: center;
            background: #f6f6f6;
            margin-top: 10px;
            border-radius: 10px;
            padding: .2rem 0;
            img {
              width: 50px;
              height: 50px;
            }
            h1 {
              font-size: 20px;
            }
            p {
              font-size: 14px;
            }
          }
          .tabs-item-active {
            width: 47%;
            align-items: center;
            background: #c90010;
            margin-top: 10px;
            border-radius: 10px;
            padding: .2rem 0;
            img {
              width: 50px;
              height: 50px;
            }
            h1 {
              color: #FFFFFF;
              font-size: 20px;
            }
            p {
              color: #FFFFFF;
              font-size: 14px;
            }
          }
        }
      }
    }
    .all-assigned {
      width: 100%;
      border-radius: 10px;
      background: #FFFFFF;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 28px 12px 12px 12px;
      box-sizing: border-box;
      .assigned-title {
        width: 4.45rem;
        height: .22rem;
        font-size: 20px;
        font-weight: bold;
        background-image: url(../../../assets/images/advantanges-of-owner-mobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        p {
          position: absolute;
          top: -.34rem;
          padding-bottom: .3rem;
          border-bottom: 2px solid #000;
        }
      }
      .assigned-list {
          width: 100%;
          // padding: .2rem;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          margin-top: 15px;
          .assigned-item {
            width: 100%;
            background: #f8fafa;
            margin-top: 10px;
            border-radius: 10px;
            padding: .3rem 0 .3rem .2rem;
            box-sizing: border-box;
            .item-border {
              width: 100%;
              flex-direction: row;
              align-items: center;
              .item-image {
                width: 50px;
                height: 50px;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
              .item-text {
                margin-left: 15px;
                .text-title {
                  font-size: 20px;
                  font-weight: bold;
                }
                .row-box {
                  display: flex;
                  flex-direction: row;
                  margin-top: 5px;
                  .text-row {
                    font-size: 16px;
                    display: flex;
                    p {
                     text-align: center;
                    }
                  }
                  .text-row:last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
    }
  }
}
@media screen and(min-width: 900px){
  .introduction-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .red-bar {
      width: 100%;
      height: 75px;
      background-image: url(../../../assets/images/gongsijianjieline.png);
      background-size: cover;
    }
    .company-profile {
      width: 80%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      padding-top: 100px;
      .profile-image {
        width: 326px;
        min-width: 326px;
        height: 480px;
      }
      .profile-right {
        height: 480px;
        min-width: 560px;
        margin-left: 45px;
        .profile-title {
          p {
            font-size: 50px;
          }
        }
        .profile-text {
          margin-top: 40px;
          font-size: 20px;
          line-height: 47px;
          p:last-child {
            margin-top: 25px;
          }
        }
      }
    }
    .platform-advantage {
      width: 100%;
      // max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 103px;
      .advantage-title {
        width: 930px;
        height: 55px;
        background-image: url(../../../assets/images/platformadv.png);
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        p {
          position: absolute;
          bottom: -5px;
          font-size: 40px;
          font-weight: bold;
          color: #333;
        }
      }
      .advantage-tabs {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 30px;
        padding: 49px 0 30px 0;
        margin-bottom: 100px;
        position: relative;
        .tabs-bg {
          position: absolute;
          top: 76px;
          width: 100%;
          height: 240px;
          background-color: #f9f9f9;
          z-index: 1;
        }
        .tabs-list {
          z-index: 2;
          width: 80%;
          max-width: 1200px;
          .tabs-item-active {
            cursor: pointer;
            height: 293px;
            width: 20%;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            background: #c90010;
            color: #FFFFFF;
            transition: all .2s linear;
            img {
              width: 64px;
              height: 64px;
            }
            h1 {
              margin-top: 42px;
              margin-bottom: 10px;
              font-size: 20px;
              letter-spacing: 3px;
              font-weight: bold;
            }
            p {
              font-size: 14px;
              letter-spacing: 6px;
            }
          }
          .tabs-item {
            cursor: pointer;
            height: 293px;
            width: 20%;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            img {
              width: 64px;
              height: 64px;
            }
            h1 {
              margin-top: 42px;
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: bold;
              letter-spacing: 3px;
            }
            p {
              font-size: 14px;
              letter-spacing: 6px;
            }
          }
        }
      }
    }
    .all-assigned {
      width: 100%;
      max-width: 1200px;
      min-width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 130px;
      .assigned-title {
        width: 1156px;
        height: 56px;
        background-image: url(../../../assets/images/advantanges-of-owner.png);
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        p {
          position: absolute;
          bottom: -5px;
          font-size: 40px;
          font-weight: bold;
          color: #333;
        }
      }
      .assigned-list {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        .assigned-item:hover {
          transform: scale(1.03);
        }
        .assigned-item {
          align-items: center;
          width: 30%;
          height: 3rem;
          margin-top: 83px;
          background: #fff5f5;
          padding: 10px;
          border-radius: 30px;
          transition: all .2s linear;
          cursor: pointer;
          .item-border {
            width: 100%;
            height: 100%;
            border: 2px solid #efc7c7;
            border-radius: 30px;
            align-items: center;
            justify-content: center;
            .item-image {
              width: 84px;
              height: 84px;
            }
            .item-text {
              align-items: center;
              .text-title {
                margin-top: 30px;
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 6px;
              }
              .text-row {
                font-size: 21px;
                margin-top: 8px;
                font-weight: 300;
                letter-spacing: 8px;
                p {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
