<template>
  <div class="home-container">
    <!-- 首页轮播图 -->
    <div class="container-swiper">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide
          v-for="item in 2"
          :key="item"
          :style="{backgroundImage: (pcOrMobile ? 'url(' + require(`@/assets/images/swiper-bg-mobile${item}.jpg`) + ')' : 'url(' + require(`@/assets/images/swiper-bg${item}.png`) + ')')}"
          >
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- 核心价值观 -->
    <div class="our-values">
      <div class="our-values-box">
        <div class="ab-title">
          <h3>OUR</h3>
          <h2>VALUES</h2>
          <div class="line"></div>
          <h4>核心</h4>
          <h5>价值观</h5>
        </div>
        <ul class="flex-row point-item">
          <li class="flex-col" v-for="vItem in valueImage" :key="vItem.id">
            <img :src="require(`@/assets/icons/${vItem.image}.png`)" alt="">
            <p>{{vItem.text}}</p>
            <span v-for="(sItem, sIndex) in vItem.slogan" :key="sIndex">{{sItem}}</span>
            <!-- <span v-for="(sItem, sIndex) in vItem.slogan1" :key="sIndex">{{vItem.slogan}}</span> -->
          </li>
        </ul>
      </div>
    </div>
    <!-- 平台优势 -->
    <div class="plat-form-adv">
      <div class="adv-box">
        <div class="title">
          <h3>Platform</h3>
          <h2>ADVANTAGE</h2>
          <div class="line"></div>
          <p>平台优势</p>
          <div class="flex-row adv-btn">
            <span @click="toInstr">了解更多</span>
            <img src="@/assets/icons/arrow-right.png" alt="">
          </div>
        </div>
        <div class="flex-row features-item">
          <div class="flex-content item-one" v-for="fItem in platformAdv" :key="fItem.id">
            <img :src="require(`@/assets/icons/${fItem.image}.png`)" alt="">
            <span>{{fItem.text}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 爆款展示 -->
    <div class="hot-style">
      <div class="style-box">
        <div class="style-title">
          <p>HOT STYLE SHOW</p>
          <h1>爆款展示</h1>
        </div>
        <div class="flex-row style-list">
          <div class="flex-content style-item" v-for="sItem in 8" :key="sItem">
            <img :src="require(`@/assets/images/hotstyleshow${sItem}.jpg`)" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      // 核心价值观
      // #region
      valueImage: [
        {
          id: 1,
          image: 'chengxin',
          text: '诚信',
          slogan: this.$store.state.currentEquipment === 1 ? ['让价格', '回归价值'] : ['让价格回归价值']
        },
        {
          id: 2,
          image: 'lita',
          text: '利他',
          slogan: this.$store.state.currentEquipment === 1 ? ['让服务', '变得极致'] : ['让服务变得极致']
        },
        {
          id: 3,
          image: 'gongtongfuyu',
          text: '共同富裕',
          slogan: this.$store.state.currentEquipment === 1 ? ['让全链路', '多方共赢'] : ['让全链路多方共赢']
        }
      ],
      // #endregion
      // 平台优势
      platformAdv: [
        {
          id: 1,
          image: 'lingyuankaidian',
          text: '0元开店无压力'
        },
        {
          id: 2,
          image: 'shenhe',
          text: '严格审核供应链'
        },
        {
          id: 3,
          image: 'baopin',
          text: '多维度爆品测试'
        },
        {
          id: 4,
          image: 'mianjianchanping',
          text: '全量检品'
        },
        {
          id: 5,
          image: 'heguihefa',
          text: '合规合法'
        },
        {
          id: 6,
          image: 'jiandantixian',
          text: '简单提现好操作'
        }
      ],
      // 轮播
      // #region
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          notNextTick: true
        },
        pagination: {
          el: '.swiper-pagination', // 与slot="pagination"处 class 一致
          clickable: true, // 轮播按钮支持点击
          autoplay: true
        }
      }
      // #endregion
    }
  },
  computed: {
    pcOrMobile () {
      return this.$store.state.currentEquipment === 1
    }
  },
  methods: {
    toInstr () {
      this.$router.push('/introduction')
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width:900px){
  .home-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .container-swiper {
      width: 100%;
      .swiper-container {
        width: 100%;
        .swiper-slide {
          width: 100%;
          height: 0;
          padding-bottom: 50%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
        }

        .swiper-pagination{
          /deep/ .swiper-pagination-bullet {
            width: .4rem !important;
            height: 4px !important;
            border-radius: 8px;
            background-color: #d16a71;
          }
          /deep/ .swiper-pagination-bullet-active {
            background-color: #efcdcf;
          }
        }
      }
    }
    .our-values {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      background: #f4f4f4;
      .our-values-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #FFFFFF;
        border-radius: 10px;
        padding: .2rem 0;
        .ab-title {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          h2 , h3 , h4, h5 {
            font-size: 20px;
            margin-left: 5px;
          }
          h5 {
            margin-left: 0;
          }
        }
        .point-item {
          width: 100%;
          justify-content: space-between;
          padding: 0 10px;
          box-sizing: border-box;
          li {
            width: 31%;
            background: #f6f6f6;
            align-items: center;
            padding: .5rem 0;
            border-radius: 10px;
            margin-top: 10px;
            img {
              width: 50px;
              height: 50px;
            }
            p {
              font-size: 16px;
              margin-top: 10px;
              font-weight: 700;
            }
            span {
              margin-top: 10px;
              font-size: 14px;
              padding: 0 10px;
              text-align: center;
            }
            span:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    .plat-form-adv {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
      background: #f4f4f4;
      .adv-box {
        width: 100%;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: .2rem;
        box-sizing: border-box;
        .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          h3 {
            display: none;
          }
          p , h2 {
            font-size: 20px;
            font-weight: bold;
          }
          p {
            margin-left: 8px;
          }
          .adv-btn {
            display: none;
          }
        }
        .features-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 5px;
          .item-one {
            width: 45%;
            flex-direction: column;
            background: #f6f6f6;
            margin-top: 10px;
            border-radius: 10px;
            padding: .3rem .1rem;
            img {
              width: 50px;
              height: 50px;
            }
            span {
              font-size: 16px;
              margin-top: 10px;
              text-align: center;
            }
          }
        }
      }
    }
    .hot-style {
      width: 100%;
      height: 17rem;
      background-image: url(../../../assets/images/baokuanbeijing.png);
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      .style-box {
        width: 90%;
        max-width: 1200px;
        padding-top: 42px;
        .style-title {
          color: #FFFFFF;
          p {
            font-size: 27px;
          }
          h1 {
            margin-top: 10px;
            font-size: 28px;
            font-weight: bold;
          }
        }
        .style-list {
          width: 100%;
          flex-wrap: wrap;
          margin-top: 26px;
          justify-content: space-between;
          .style-item {
            cursor: pointer;
            width: 50%;
            transition: all .2s linear;
          }
          .style-item:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
@media screen and(min-width: 900px){
  .home-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .container-swiper {
      width: 100%;
      .swiper-container {
        width: 100%;
        .swiper-slide {
          width: 100%;
          height: 0;
          padding-bottom: 28.1%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
        }

        .swiper-pagination{
          /deep/ .swiper-pagination-bullet {
            width: 60px !important;
            height: 8px !important;
            border-radius: 8px;
            background-color: #d16a71;
          }
          /deep/ .swiper-pagination-bullet-active {
            background-color: #efcdcf;
          }
        }
      }
    }
    .our-values {
      width: 100%;
      padding: 60px 0;
      margin-top: 60px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      .our-values-box {
        width: 80%;
        max-width: 1200px;
        display: flex;
        align-items: center;
        .ab-title {
          width: 20%;
          h3 {
            font-size: 27px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-weight: 500;
          }
          h2 {
            font-size: 37px;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          .line {
            width: 62px;
            height: 4px;
            background-color: #333333;
            border-radius: 15px;
          }
          h4 {
            font-weight: 500;
            font-size: 27px;
            margin-top: 20px;
            margin-bottom: 10px;
            // border-top: 2px solid #3b4a5b;
          }
          h5 {
            font-weight: 500;
            font-size: 27px;
          }
        }
        .point-item {
          width: 80%;
          li {
            cursor: pointer;
            width: 33%;
            height: 2rem;
            background-color: #FFFFFF;
            border-radius: 10px;
            padding: 25px;
            margin: 0 10px;
            justify-content: center;
            transition: all .2s linear;
            img {
              width: 35px;
              height: 35px;
            }
            p {
              font-size: 27px;
              color: #333333;
              margin: 23px 0 18px 0;
              font-size: 18px;
              font-weight: 700;
            }
            span {
              font-size: 15px;
            }
          }
          li:hover {
            transform: scale(1.05);
          }
          li:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .plat-form-adv {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 41px;
      padding-bottom: 65px;
      .adv-box {
        display: flex;
        flex-direction: row;
        .title {
          width: 20%;
          max-width: 320px;
          background: #F4F4F4;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 107px 0 61px 36px;
          h3 {
            font-size: 24px;
          }
          h2 {
            margin-top: 7px;
            font-size: 30px;
            font-weight: 700;
            padding-bottom: 18px;
          }
          p {
            font-size: 30px;
            color: #333333;
            font-weight: 700;
            margin-top: 63px;
          }
          .line {
            width: 70px;
            height: 4px;
            background-color: #333333;
            border-radius: 5px;
          }
          .adv-btn {
            cursor: pointer;
            width: 130px;
            border-radius: 30px;
            padding: 12px 23px;
            background-color: #c90010;
            justify-content: space-between;
            align-items: center;
            margin-top: 91px;
            span {
              font-size: 19px;
              color: #FFFFFF;
              font-weight: 600;
              white-space: nowrap;
            }
            img {
              width: 38px;
              height: 9px;
            }
          }
        }
      }
      .features-item {
        width: 80%;
        max-width: calc(100% - 320px);
        // margin-left: 30px;
        flex-wrap: wrap;
        .item-one {
          cursor: pointer;
          width: calc(33% - 30px);
          min-width: calc(33% - 30px);
          flex-direction: column;
          margin-left: 30px;
          border: 1px solid #d1caca;
          margin-top: 30px;
          border-radius: 30px;
          img {
            width: 90px;
            height: 90px;
          }
          span {
            margin-top: 29px;
            font-size: 20px;
            color: #333333;
            font-weight: 500;
          }
        }
        .item-one:nth-child(-n+3) {
          margin-top: 0;
        }
        .item-one:hover {
          box-shadow: 0 0 15px rgba(0, 0, 0, .25);
        }
      }
    }
    .hot-style {
      width: 100%;
      height: 795px;
      background-image: url(../../../assets/images/baokuanbeijing.png);
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      .style-box {
        width: 80%;
        max-width: 1200px;
        padding-top: 42px;
        .style-title {
          color: #FFFFFF;
          p {
            font-size: 27px;
          }
          h1 {
            margin-top: 10px;
            font-size: 28px;
            font-weight: bold;
          }
        }
        .style-list {
          width: 100%;
          flex-wrap: wrap;
          margin-top: 26px;
          .style-item {
            cursor: pointer;
            width: 25%;
            transition: all .2s linear;
          }
          .style-item:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

</style>
